<template>
  <div class="modal fade"
       id="modal-invite"
       tabindex="-1"
       role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" v-if="account">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Invite</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-light-info" v-if="nickName">
            Your nick name is <b>{{ nickName }}</b>. <router-link @click="hide" :to="{name: 'profile'}">Click here</router-link> to change nick name
          </div>
          <div class="form-group">
            <label>Email</label>

            <input :class="['form-control', {'is-invalid': errorFor('email')}]"
                   type="text"
                   :disabled="loading"
                   v-model="form.email">
            <v-errors :errors="errorFor('email')" />
          </div>
          <div class="form-group">
            <label>Role</label>
            <select :class="['form-control', {'is-invalid': errorFor('role')}]"
                    :disabled="loading"
                    v-model="form.role">
              <option value="">Select Role</option>
              <option value="2" v-if="account.role === 1">Manager</option>
              <option value="4">Editor</option>
              <option value="3">Viewer</option>
            </select>
            <v-errors :errors="errorFor('role')" />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="sendInvitation">Send Invitation</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
      form: {
        email: '',
        role: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      account: 'auth/getAccount',
      nickName: 'auth/getNickName'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-invite'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    sendInvitation() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/invitation', this.form)
        .then(() => {
          this.hide();
          this.$emit('invited')
          this.form.email = '';
          this.form.role = '';
        })
        .catch((err) => {
            this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>